<template>
  <div class="more-nutrient-elements">
    <ul>
      <li>
        <span>营养元素</span>
        <span>每100g</span>
        <span>食物亮点</span>
      </li>
      <li>
        <span>热量</span>
        <span>{{ enercCal }} 卡</span>
        <span>{{ enercHighlight }}</span>
      </li>

      <li v-for="(element, index) of nutritionList" :key="index" v-bind:class="indent(element.name)">
        <span>{{ element.name }}</span>
        <span>{{ element.content }}</span>
        <span>{{ element.highlight }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'moreNutrientElements',
    data() {
      return {
        enercCal:0,
        enercHighlight :"",
        nutritionList: []
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      indent(name){
          if(["饱和脂肪酸", "单不饱和脂肪酸", "多不饱和脂肪酸", "反式脂肪酸", "不可溶性膳食纤维", "可溶性膳食纤维"].indexOf(name) != -1){
              return "indent";
          }
      },
      getData() {

            var that = this;
            that.id = this.$route.query.id;
            //this.$ajax.post("http://api.np.local:7777/api/food/detail", {
            this.$ajax.post(this.API.api.apiurl.getFoodDetail, {
                id: that.id,
            },{
            }).then(res => {
                var data = res.data.data;
                that.id = data.id;
                that.tags = data.food_highlight;
                that.name = data.name;
                document.title = data.name+"的热量，蛋白质，脂肪，碳水化合物各营养素含量"
                that.imageUrl = data.image_url;
                that.enerc = data.enerc_cal;
                that.enercCal = data.enerc_cal;
                that.enercKj = parseFloat(data.enerc_kj).toFixed(3)

                // 判断是否有热量, 有就展示

                data.food_highlight.forEach(item => {
                    if(item.indexOf("卡路里") != -1){
                        var enercHighlight = item;
                        that.enercHighlight = enercHighlight;
                    }
                });

                data.facts.forEach(el => {
                    var item = { name: el.name, content: el.weight+el.unit, highlight: el.highlight , indent:false};
                    if(el.weight == null){
                        item.content = "-";
                    }

                    if(that.indent(item.name) == "indent"){
                        item.name = "-" + item.name;
                        item.indent = true;
                    }

                    that.nutritionList.push(
                        item
                    )
                });

                });
        }
    }
  }
</script>

<style lang="scss" scoped>
.more-nutrient-elements {
  padding: 10px;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #CCCCCC;

    li {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
      background-color: #F5F5F5;
      padding: 7px 10px;

      &:indent{
          color:#aaa;
          font-size:10px;
      }

      &:first-of-type {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 20px;
        padding: 17px 10px;
      }

      &:nth-of-type(2n) {
        background-color: #fff;
      }

      span {
        flex: 1;
        text-align: left;
      }
    }
  }
}
</style>
